import '../../sass/theme/_child_theme.scss';
(function($) {
    $('.gallery-featherlight').each(function(_i, gallery) { 
        $(gallery).featherlightGallery({
            previousIcon: '&#8592',
            nextIcon: '&#8594',
            galleryFadeIn: 300,
            openSpeed: 150,
            variant: 'gal',
            filter: '.gallery-image'
        });
    });

    $(document).ready(function($) {
        $('.qtyPlus').on('click',function(e){
            e.preventDefault();
            const input_id = $(this).attr('data-for');
            const input = $(`#${input_id}`);
            var val = parseInt(input.val());
            input.val( val+1 );
        });

        $('.qtyMinus').on('click',function(e){
            e.preventDefault();
            const input_id = $(this).attr('data-for');
            const input = $(`#${input_id}`);
            var val = parseInt(input.val());
            if(val !== 1){
                input.val( val-1 );

            }
         });

        $('.qty').on('click', function(e) {
            e.preventDefault();
        });
    });
})(jQuery);

